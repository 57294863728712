import React, { FunctionComponent, useState, MouseEvent } from 'react'
import styled from 'styled-components'
import SvgClose from '../generated/svg/close'
import SvgInstagramLogo from '../generated/svg/instagram-logo'
import SvgMenu from '../generated/svg/menu'
import { useBreakpoint } from '../hooks/use-break-point'
import { Menu } from './menu'
import { ReserveButton } from './reserve-button'

const Outer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0rem;
  align-items: center;
`

const Navigator = styled.div`
  display: flex;
  align-items: center;
`

const NavigatorItem = styled.a`
  display: block;
  padding: 1rem;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.75;
  }
  @media (max-width: 40rem){
    font-size: 1.25rem;
    &:nth-child(n+2){
      display: none;
    }
  }
`

const NavigatorItemDivider = styled.div`
  width: 0.125rem;
  height: 0.8em;
  padding: 1rem;
  @media (max-width: 40rem){
    display: none;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: currentcolor;
  }
  
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

const InstagramIconContainer = styled.a`
  display: block;
  height: 1.25rem;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.75;
  }
  @media (max-width: 40rem){
    height: 1.5rem;
  }
  svg {
    display: block;
    width: auto;
    height: 100%;
  }
`

const ReserveButtonContainer = styled.div`
  @media (max-width: 40rem){
    display: none;
  }
`

const MenuButtonContainer = styled.div`
  cursor: pointer;
  z-index: 100;
  svg {
    height: 1.3rem;
    width: auto;
    display: block;
  }
`

const MobileReserve = styled.a`
  position: fixed;
  transform-origin: bottom right;
  transform: rotate(-90deg);
  right: 0.75rem;
  top: 2.25rem;
  text-decoration: underline;
  display: block;
  padding: 0.5rem;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.75;
  }
`

type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const isMobile = useBreakpoint('40rem')
  const [open, setOpen] = useState(false)
  const scroll = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    document.getElementById((e.currentTarget.hash || 'top').replace(/^#/, ''))?.scrollIntoView({behavior: 'smooth'})
  }
  return <Outer>
    <Navigator>
      <NavigatorItem onClick={scroll} href="/" style={{zIndex: 100, textDecoration: 'none'}}>eman</NavigatorItem>
      <NavigatorItemDivider />
      <NavigatorItem onClick={scroll} href="#concept">concept</NavigatorItem>
      <NavigatorItem onClick={scroll} href="#about">about</NavigatorItem>
      <NavigatorItem onClick={scroll} href="#career">career</NavigatorItem>
      <NavigatorItem onClick={scroll} href="#information">information</NavigatorItem>
    </Navigator>
    <RightContainer>
      <InstagramIconContainer href="https://www.instagram.com/restaurante_eman/" target="_blank">
        <SvgInstagramLogo />
      </InstagramIconContainer>
      <ReserveButtonContainer>
        <ReserveButton />
      </ReserveButtonContainer>
      {
      isMobile &&
        <MenuButtonContainer onClick={() => setOpen(v => !v)}>
          {open ? <SvgClose /> : <SvgMenu />}
        </MenuButtonContainer>
      }
    </RightContainer>
    {
      isMobile &&
      <Menu onClose={() => setOpen(false)} open={open}/>
    }
    {
      isMobile && <MobileReserve href="https://www.tablecheck.com/shops/eman/reserve" target="_blank">
        reserve
      </MobileReserve>
    }
  </Outer>
}