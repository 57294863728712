import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Swiper as SwiperClass, Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SvgLeftArrow from '../generated/svg/left-arrow'
import SvgLogo from '../generated/svg/logo'
import SvgRightArrow from '../generated/svg/right-arrow'
import { useBreakpoint } from '../hooks/use-break-point'

const Outer = styled.div`
  position: relative;
  .swiper-slide {
    transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
  }
`

const ImageContainer = styled.div`
  height: 48rem;
  @media (max-width: 40rem){
    max-height: 100vh;
    max-height: 100lvh;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    height: 2rem;
    width: auto;
  }
  z-index: 1;
`

const Controller = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
  display: flex;
  align-items: center;
`

const Button = styled.div`
  padding: 1rem;
  cursor: pointer;
  svg {
    display: block;
    height: 0.8rem;
    width: auto;
  }
`

const Image = styled.img`
`

const Page = styled.div``

type TopSliderProps = {
  images: {
    desktop: string,
    mobile: string
  }[]
}
export const TopSlider: FunctionComponent<TopSliderProps> = ({images}) => {
  const isMobile = useBreakpoint('40rem')
  const [current, setCurrent] = useState(0)
  const [swiper, setSwiper] = useState<SwiperClass>()
  console.log(images)
  return <Outer>
    <Swiper
      effect="fade"
      autoplay={{
        delay: 2500,
      }}
      modules={[
        EffectFade,
        Autoplay
      ]}
      speed={1000}
      loop
      onSwiper={swiper => {
        setSwiper(swiper)
        setCurrent(swiper.realIndex)
        swiper.slideTo(1)
        swiper.on('activeIndexChange', () => {
          setCurrent(swiper.realIndex)
        })
      }}
    >
      
      
      {
        images.map((image, i) => {
          return <SwiperSlide key={i}>
            <ImageContainer>
              {
                <Image src={isMobile ? image.mobile : image.desktop}/>
              }
            </ImageContainer>
          </SwiperSlide>
        })
      }
      <LogoContainer>
        <SvgLogo />
      </LogoContainer>
    </Swiper>
    <Controller>
      <Button onClick={() => swiper?.slidePrev()}>
        <SvgLeftArrow />
      </Button>
      <Page>
        {current + 1} / {images.length}
      </Page>
      <Button onClick={() => swiper?.slideNext()}>
        <SvgRightArrow />
      </Button>
    </Controller>
    
  </Outer>
}