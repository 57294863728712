import * as React from "react";
import { SVGProps } from "react";
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 162.055 31.354"
    role="img"
    {...props}
  >
    <path
      d="M122.174 15.866a15.4 15.4 0 0 0-2.546-8.5 5.093 5.093 0 0 1 1.472-.517l-.03-3.212a7.786 7.786 0 0 0-3.545 1.171c-6.105-5.99-15.91-5.898-21.901.206-5.991 6.105-5.899 15.91.206 21.901 6.026 5.914 15.68 5.911 21.702-.007a6.824 6.824 0 0 0 3.568 1.157v-3.2a3.57 3.57 0 0 1-1.481-.494 15.415 15.415 0 0 0 2.555-8.505Zm-6.9 0a11.073 11.073 0 0 1 1.868-6.237 12.107 12.107 0 0 1 .04 12.407 11.422 11.422 0 0 1-1.906-6.17h-.002Zm-20.778 0C94.49 9.141 99.937 3.685 106.662 3.68a12.177 12.177 0 0 1 8.382 3.333 14.027 14.027 0 0 0-3.07 8.854 14.24 14.24 0 0 0 3.107 8.818c-4.866 4.644-12.574 4.464-17.218-.401a12.178 12.178 0 0 1-3.368-8.417ZM78.326 8.706c-1.57-4.573-3.964-7.187-7.322-8-3.021-.727-6.92-.475-9.728 1.994-2.807-2.468-6.7-2.72-9.728-1.994-3.356.808-5.751 3.423-7.32 8-1.081 3.148-1.113 9.8-1.113 10.544 0 2.288.18 12.1.18 12.1l3.363-.062s-.18-9.763-.18-12.039c0-2.333.2-7.31.929-9.453 1.167-3.4 2.78-5.3 4.93-5.818 1.083-.258 4.494-.826 6.824 1.346a58.067 58.067 0 0 0-3.1 5.965 8.79 8.79 0 0 0-.861 4.774 6.237 6.237 0 0 0 12.157 0 8.983 8.983 0 0 0-.922-4.912 57.861 57.861 0 0 0-3.04-5.827c2.33-2.172 5.74-1.6 6.822-1.346 2.15.518 3.762 2.421 4.93 5.82.734 2.136.93 7.116.93 9.45 0 2.276-.181 12.038-.181 12.04l3.363.061s.18-9.814.18-12.1c-.001-.748-.033-7.402-1.113-10.543ZM64.032 15.53a2.9 2.9 0 0 1-5.513 0 6.167 6.167 0 0 1 .7-3.076c.18-.43 1.08-2.327 2.057-4.086.984 1.772 1.9 3.71 2.132 4.26.456.897.671 1.897.625 2.902h-.001ZM27.89 13.391H3.843c1.368-6.64 7.86-10.914 14.5-9.546a12.276 12.276 0 0 1 9.547 9.546m3.463 2.475C31.457 7.21 24.523.106 15.866.001 7.21-.103.106 6.831.001 15.488s6.83 15.76 15.487 15.865a15.677 15.677 0 0 0 14.107-8.46l-2.925-1.319c-3.112 5.977-10.48 8.298-16.456 5.186a12.2 12.2 0 0 1-6.55-10.2h27.623c.01-.237.07-.456.07-.694M162.055 31.352V13.726c0-8.91-5.593-13.366-13.426-13.4-4.394-.016-8.637 1.367-11.464 4.1V.934h-3.231V31.28h3.317v-18.3c0-5.918 5.2-9.408 11.45-9.408 6.72 0 9.938 4.308 9.938 10.15v17.625l3.416.005Z"
      fill="currentcolor"
    />
  </svg>
);
export default SvgLogo;
