import React, { FunctionComponent, MouseEvent } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100lvh;
  width: 100vw;
  width: 100lvw;
  background-color: #fff4;
  display: grid;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s, backdrop-filter 1s;
  &.open {
    opacity: 1;
    pointer-events: all;
    backdrop-filter: blur(2rem) saturate(1.5);
    transition: opacity 0.4s, backdrop-filter 0.4s;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  margin: 2rem;
`

const Item = styled.a`
  padding: 0.75rem;
  font-size: 1.25rem;
  letter-spacing: 0.02em;
  &:hover {
    text-decoration: underline;
  }
`

type MenuProps = {
  onClose: () => void
  open: boolean
}
export const Menu: FunctionComponent<MenuProps> = ({onClose, open}) => {

  const scroll = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    document.getElementById((e.currentTarget.hash || 'top').replace(/^#/, ''))?.scrollIntoView({behavior: 'smooth'})
    onClose()
  }
  return <Outer className={open ? 'open' : undefined}>
    <Inner>
      <Item onClick={scroll} href="/">top</Item>
      <Item onClick={scroll} href="#concept">concept</Item>
      <Item onClick={scroll} href="#about">about</Item>
      <Item onClick={scroll} href="#career">career</Item>
      <Item onClick={scroll} href="#information">information</Item>
    </Inner>

  </Outer>
}