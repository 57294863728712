import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { BlackReserveButton } from './reserve-button'

const Outer = styled.div`
  color: black;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem 3rem;
  @media (max-width: 40rem){
    padding-top: 2.5rem;
    padding-bottom: 1rem;
  }
`

const ReserveButtonContainer = styled.div`
  max-width: 24rem;
  margin-bottom: 4rem;
  @media (max-width: 40rem){
    margin-bottom: 2.5rem;
  }
`

const Copyright = styled.div`
  font-size: 0.9375rem;
  letter-spacing: 0.02em;
  @media (max-width: 40rem){
    font-size: 0.75rem;
  }
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <ReserveButtonContainer>
      <BlackReserveButton />
    </ReserveButtonContainer>
    <Copyright>
      Copyright © 2022 eman All rights reserved.
    </Copyright>
  </Outer>
}