import * as React from "react";
import { SVGProps } from "react";
const SvgLeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6.564 10.648"
    role="img"
    {...props}
  >
    <path
      d="M6.044 10.108 1.08 5.324 6.044.54"
      fill="none"
      stroke="currentcolor"
      strokeWidth="1.5px"
    />
  </svg>
);
export default SvgLeftArrow;
