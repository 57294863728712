import * as React from "react";
import { SVGProps } from "react";
const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.958}
    height={18.939}
    viewBox="0 0 19.958 18.939"
    role="img"
    {...props}
  >
    <g data-name="Group 8891" fill="none" stroke="#fff">
      <path data-name="Path 95681" d="M0 .5h19.958" />
      <path data-name="Path 95682" d="M0 9.947h19.958" />
      <path data-name="Path 95683" d="M0 18.439h19.958" />
    </g>
  </svg>
);
export default SvgMenu;
