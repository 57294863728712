import * as React from "react";
import { SVGProps } from "react";
const SvgRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6.564 10.648"
    role="img"
    {...props}
  >
    <path
      d="m.52.54 4.963 4.784-4.962 4.784"
      fill="none"
      stroke="currentcolor"
      strokeWidth="1.5px"
    />
  </svg>
);
export default SvgRightArrow;
