import * as React from "react";
import { SVGProps } from "react";
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="\u30EC\u30A4\u30E4\u30FC_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 18.9"
    xmlSpace="preserve"
    enableBackground="new 0 0 20 18.9"
    role="img"
    {...props}
  >
    <style>{".st0{fill:none;stroke:#fff}"}</style>
    <path
      id="Path_95682_00000159466065640993595320000011094106433482021021_"
      className="st0"
      d="m1.2.7 17.5 17.5"
    />
    <path
      id="Path_95682_00000164505554026910256990000017993358352443244705_"
      className="st0"
      d="M1.2 18.2 18.7.7"
    />
  </svg>
);
export default SvgClose;
