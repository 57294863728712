import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useExternalCss } from '../hooks/use-external-css';

export const GlobalStyle = createGlobalStyle`
  :root {
    font-family: 'din-2014', "Noto Sans Japanese", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.0625rem;
    letter-spacing: 0.02em;
    color: white;
  }

  a {
    text-decoration: inherit;
    color: inherit;
  }
`

export const RootCss: FunctionComponent = () => {
  useExternalCss('https://unpkg.com/swiper@8/swiper-bundle.min.css')
  useExternalCss('https://use.typekit.net/vwb5qjy.css')
  useExternalCss('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400&display=swap')
  return <GlobalStyle />
}