import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useContentsQuery } from '../generated/graphql'
import SvgAddressIcon from '../generated/svg/address-icon'
import { useBreakpoint } from '../hooks/use-break-point'
import { Footer } from './footer'
import { Header } from './header'
import { Image } from './image'
import { TopSlider } from './top-slider'

export function isNullish(value: any): value is null | undefined {
  return value === null || typeof value === 'undefined'
}

export function isNotNullish<T>(value: T): value is Exclude<T, null | undefined> {
  return !isNullish(value)
}

const Outer = styled.div`
  transition: opacity 0.8s 0.4s;
  &.hide {
    opacity: 0;
  }
`

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`

const ContentContainer = styled.div`
  height: 48rem;
  position: relative;
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const TextContainer = styled.div`
  position: absolute;
  z-index: 1;
  filter: drop-shadow(0 0 2rem #000) drop-shadow(0 0 0.5rem #0008);
  @media (max-width: 40rem){
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    transform: translate(-50%, -50%);
    width: max-content;
    max-width: calc(100vw - 4rem);
  }
`

const Title = styled.div`
  font-size: 1.3125rem;
  letter-spacing: 0.02em;
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid white;
    width: 3rem;
    margin: 2rem 0;
  }
`

const Text = styled.div`
  white-space: pre-wrap;
  font-family: 'Noto Sans JP';
  font-size: 0.9375rem;
  line-height: 1.93;
  font-weight: 300;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`

const BusinessHoursTable = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 0.35rem 1rem;
  justify-content: start;
  margin-bottom: 2.5rem;
  @media (max-width: 40rem){
    grid-template-columns: repeat(3, auto);
  }
`

const Cell = styled.div`
  white-space: pre-wrap;
  font-family: 'Noto Sans JP';
  font-size: 0.9375rem;
  line-height: 1.93;
  font-weight: 300;
  small {
    font-size: 0.8rem;
    margin-left: -1rem;
  }
`

const InformationNote = styled(Text)`
  @media (max-width: 40rem){
    font-size: 0.75rem !important;
  }
`

const InformationRow = styled.div`
  margin-top: 2.5rem;
  display: flex;
  @media (max-width: 40rem){
    flex-direction: column;
  }
`

const AddressContainer = styled.a`
  display: flex;
  gap: 1rem;
  @media (max-width: 40rem){
    margin-bottom: 2rem;
    position: relative;
  }
  &:hover {
    text-decoration: underline;
  }
  &::after {
    content: '';
    height: calc(0.9375rem * 2.93);
    margin-top: calc(0.9375rem * 0.93 / 2);
    width: 1px;
    background-color: white;
    margin-left: 1rem;
    margin-right: 3rem;
    @media (max-width: 40rem){
      position: absolute;
      bottom: -1rem;
      left: 0;
      height: 1px;
      margin-left: 0;
      margin-right: 0;
      width: 3rem;
    }
  }
`

const AddressIconContainer = styled.div`
  display: grid;
  place-items: center;
  transition: opacity 0.4s;
  ${AddressContainer}:hover & {
    opacity: 0.75;
  }
  svg {
    display: block;
    height: 3.5rem;
    width: auto;
  }
`

type MainProps = {

}
export const Main: FunctionComponent<MainProps> = () => {
  const contentsQuery = useContentsQuery()
  const contents = contentsQuery.data?.pageBy?.page_fields
  const isMobile = useBreakpoint('40rem')
  return <Outer className={contents ? undefined : 'hide'}>
    <HeaderContainer>
      <Header />
    </HeaderContainer>
    <ContentContainer style={{maxHeight: "100vh"}} id="top">
      <TopSlider images={contents?.topImages?.filter(isNotNullish).map(image => {
        return {
          desktop: image.image?.mediaItemUrl ?? '',
          mobile: image.mobileImage?.mediaItemUrl ?? ''
        }
      }) ?? []}/>
    </ContentContainer>
    <ContentContainer id="concept">
      <Image src="/images/2.jpg" mobileSrc="/images/2m.jpg" />
      <TextContainer style={{top: '8rem', right: '8vw'}}>
        <Title>concept</Title>
        <Text>
          {contents?.conceptText}
        </Text>
      </TextContainer>
    </ContentContainer>
    <ContentContainer id="about">
      <Image src="/images/3.jpg" mobileSrc="/images/3m.jpg" />
      <TextContainer style={{top: '8rem', left: '8vw'}}>
        <Title>about</Title>
        <Text>
          {contents?.aboutText}
        </Text>
      </TextContainer>
    </ContentContainer>
    <ContentContainer id="career">
      <Image src="/images/4.jpg" mobileSrc="/images/4m.jpg" />
      <TextContainer style={{top: '8rem', right: '8vw'}}>
        <Title>career</Title>
        <Text>
          {contents?.careerText}
        </Text>
      </TextContainer>
    </ContentContainer>
    <ContentContainer id="information">
      <Image src="/images/5.jpg" mobileSrc="/images/5m.jpg" />
      <TextContainer style={{top: '8rem', left: '8vw'}}>
        <Title>information</Title>
        <BusinessHoursTable>
          {contents?.businessHours?.map((bh, i) => {
            return <>
              {i > 0 && <Cell style={{gridColumn: `span ${isMobile ? 3 : 5}`, height: '1rem'}} />}
              <Cell style={{gridRow: isMobile ? `span 1` : `span ${bh?.days?.map(d => d?.times).flat(1).length || 1}`, gridColumn: `span ${isMobile ? 3 : 1}`}}>{bh?.type}</Cell>
              <Cell style={{gridRow: `span ${bh?.days?.map(d => d?.times).flat(1).length || 1}`, display: isMobile ? 'none' : ''}}>・</Cell>
              {bh?.days?.map(d => {
                return <>
                  <Cell style={{gridRow: `span ${d?.times?.length || 1}`}}>{isMobile && '・'}{d?.day}</Cell>
                  {d?.times?.map(t => {
                    return <>
                      <Cell>{t?.time}</Cell>
                      <Cell><small>{t?.note}</small></Cell>
                    </>
                  })}
                </>
              })}
            </>
          })}
        </BusinessHoursTable>
        <InformationNote>
          {contents?.informationNote}
        </InformationNote>
        <InformationRow>
          <AddressContainer href="https://www.google.com/maps/uv?pb=!1s0x601889769bc43803%3A0xcb3033455fa2feaa!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipO8hU7QnkLYH_nliE-FxLixnDC1EAXho9v30gob%3Dw426-h320-k-no!5zZW1hbiAtIEdvb2dsZSDmpJzntKI!15sCgIgAQ&imagekey=!1e10!2sAF1QipO8hU7QnkLYH_nliE-FxLixnDC1EAXho9v30gob&hl=ja&sa=X&ved=2ahUKEwjP-LLOrc_6AhVmpVYBHRZaBIIQoip6BAhoEAM" target="_blank">
            <InformationNote>
              {contents?.address}
            </InformationNote>
            <AddressIconContainer>
              <SvgAddressIcon />
            </AddressIconContainer>
          </AddressContainer>
          <InformationNote>
            <a href={`mailto:${contents?.email}`}>{contents?.email}</a><br />
            <a href={`tel:${contents?.phoneNumber}`}>{contents?.phoneNumber}</a>
          </InformationNote>
        </InformationRow>
      </TextContainer>
    </ContentContainer>
    <Footer />
  </Outer>
}