import * as React from "react";
import { SVGProps } from "react";
const SvgInstagramLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.4 20.404"
    role="img"
    {...props}
  >
    <path
      d="M16.974 4.691a1.276 1.276 0 1 1-2.552 0 1.276 1.276 0 0 1 2.552 0"
      fill="currentcolor"
    />
    <path
      d="M14.645 20.402H5.754A5.763 5.763 0 0 1 0 14.652v-8.9A5.76 5.76 0 0 1 5.754 0h8.891A5.758 5.758 0 0 1 20.4 5.752v8.9a5.76 5.76 0 0 1-5.754 5.752l-.001-.002ZM5.754 1.613a4.147 4.147 0 0 0-4.141 4.139v8.9a4.147 4.147 0 0 0 4.141 4.139h8.891a4.144 4.144 0 0 0 4.142-4.139v-8.9a4.147 4.147 0 0 0-4.141-4.142l-8.892.003Z"
      fill="currentcolor"
    />
    <path
      d="M10.197 15.249a5.058 5.058 0 1 1 5.053-5.063v.007a5.057 5.057 0 0 1-5.053 5.056Zm0-8.526a3.471 3.471 0 1 0 3.47 3.472v-.004a3.47 3.47 0 0 0-3.47-3.468Z"
      fill="currentcolor"
    />
  </svg>
);
export default SvgInstagramLogo;
