import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import React, { createContext } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Main } from './components/main';
import { ResetCss } from './components/reset-css';
import { RootCss } from './components/root-css';
import { GQLContentsQuery } from './generated/graphql';
import SvgLogo from './generated/svg/logo';

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://eman.asilart.com/wp/index.php?graphql',
    fetchOptions: {
      mode: 'cors'
    }
  }),
  cache: new InMemoryCache()
})

const Background = styled.div`
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  z-index: -100;
  svg {
    height: 2rem;
    width: auto;
  }
`


export const App: FunctionComponent = () => {

  return <>
    <ApolloProvider client={apolloClient}>
      <ResetCss />
      <RootCss />
      <Main />
      <Background>
        <SvgLogo />
      </Background>
    </ApolloProvider>
  </>
}