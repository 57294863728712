import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const Outer = styled.a`
  padding: 0.375rem 2rem;
  border-radius: 2rem;
  border: 1px solid white;
  display: block;
  transition: all 0.4s;
  color: white;
  transition: all 0.4s;
  &:hover {
    background-color: white;
    color: black;
  }
`

const BlackOuter = styled(Outer)`
  border: 1px solid black;
  color: black;
  &:hover {
    background-color: black;
    color: white;
  }
`

const Label = styled.div`
  text-decoration: none !important;
`

type ReserveButtonProps = {

}
export const ReserveButton: FunctionComponent<ReserveButtonProps> = () => {
  return <Outer href="https://www.tablecheck.com/shops/eman/reserve" target="_blank">
    <Label>
      reserve
    </Label>
  </Outer>
}
export const BlackReserveButton: FunctionComponent<ReserveButtonProps> = () => {
  return <BlackOuter href="https://www.tablecheck.com/shops/eman/reserve" target="_blank">
    <Label>
      reserve
    </Label>
  </BlackOuter>
}